<template>
  <div class="flex w-full">
    <div class="w-full" :class="{ 'xl:w-2/3': developer_tools }">
      <div class="grid grid-cols-2 ml-5 mt-10 text-xl font-large text-gray-700">
        <div>List all Customers</div>
        <div class="flex justify-end hover:cursor-pointer">
          <ChevronDoubleRightIcon
            v-if="developer_tools"
            class="h-5 w-5 text-gray-400"
            aria-hidden="true"
            @click="developer_tools = false"
          />
          <ChevronDoubleLeftIcon
            v-else
            class="h-5 w-5 text-gray-400"
            aria-hidden="true"
            @click="developer_tools = true"
          />
        </div>
      </div>
      <div class="flex">
        <div class="ml-4 text-sm font-sm text-gray-500">
          <button class="bg-green-200 rounded-full text-xs font-xs px-2 mx-1">
            GET</button
          >https://api.coreclimate.io/v1/customers
        </div>
      </div>
      <div>
        <hr class="mt-2 mb-5 mx-5" />
      </div>

      <div>
        <div class="ml-5 mt-10 text-sm font-small text-gray-500"></div>
      </div>

      <form class="mb-5">
        <div class="ml-5 mr-5">
          <div>
            <h2 class="text-sm text-gray-900">QUERY PARAMS</h2>

            <div
              class="mt-5 space-y-2 border border-gray-200 pb-0 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:pb-0 bg-gray-100 rounded-lg p-2 mb-5"
            >
              <div
                class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2"
              >
                <div>
                  <div class="flex">
                    <span
                      class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                      >page</span
                    ><span
                      class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                      >integer</span
                    >
                  </div>

                  <div class="text-sm font-sm text-gray-600">
                    The page number to retrieve
                  </div>
                </div>

                <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <div
                    class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md"
                  >
                    <input
                      type="text"
                      class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                      v-model="page"
                    />
                  </div>
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2"
              >
                <div>
                  <div class="flex">
                    <span
                      class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                      >page_size</span
                    ><span
                      class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                      >integer</span
                    >
                  </div>

                  <div class="text-sm font-sm text-gray-600">
                    The maximum number of customers to retrieve per page
                  </div>
                </div>

                <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <div
                    class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md"
                  >
                    <input
                      type="text"
                      class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                      v-model="page_size"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="flex border-0 justify-end">
              <button
                type="button"
                class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                @click="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>

      <div class="p-5">
        <ul
          role="list"
          class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3"
        >
          <li
            v-for="customer in customers"
            :key="customer._id"
            class="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center drop-shadow"
          >
            <div class="flex flex-1 flex-col p-8">
              <h4 class="mb-6 text-sm font-medium text-gray-900">
                {{ customer.name }}
              </h4>
              <img
                class="mx-auto h-32 w-32 cover flex-shrink-0"
                :src="customer.logo_url"
                alt=""
              />
              <h3 class="mt-6 text-sm font-medium text-gray-900">
                {{ customer.email }}
              </h3>
              <dl class="mt-1 flex flex-grow flex-col justify-between">
                <dt class="sr-only">Address</dt>
                <dd class="text-sm text-gray-500">{{ customer.address }}</dd>
              </dl>
            </div>
            <div>
              <div class="-mt-px flex divide-x divide-gray-200">
                <div class="-ml-px flex w-0 flex-1">
                  <button
                    @click="showDetails(customer._id)"
                    class="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                  >
                    <LightningBoltIcon
                      class="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    Details
                  </button>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <!-- <div class="px-4 sm:px-6 lg:px-8">
        <div class="sm:flex sm:items-center">
          <div class="sm:flex-auto">
            <h1 class="text-base font-semibold leading-6 text-gray-900">
              Meters
            </h1>
            <p class="mt-2 text-sm text-gray-700">
              A list of all the meters using the filtering above
            </p>
          </div>
        </div>
        <div class="mt-8 flow-root">
          <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div
              class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
            >
              <table class="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      Meter
                    </th>
                    <th
                      scope="col"
                      class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Customer
                    </th>
                    <th
                      scope="col"
                      class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Address
                    </th>
                    <th
                      scope="col"
                      class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer"
                      @click="sort('last_consumption_date')"
                    >
                      Last Reading
                    </th>
                    <th
                      scope="col"
                      class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer"
                      @click="sort('last_scanned_date')"
                    >
                      Last Scanned
                    </th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                  <tr v-for="meter in meters" :key="meter.meter_number">
                    <td
                      class="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0"
                    >
                      <div class="flex items-center">
                        <div class="h-11 w-11">
                          <img
                            class="h-19 w-11"
                            :src="require('@/assets/sse.png')"
                            alt=""
                          />
                        </div>
                        <div
                          class="ml-4 hover:cursor-pointer"
                          @click="showDetails(meter._id)"
                        >
                          <div class="font-medium text-gray-900">
                            {{ meter.meter_number }}
                          </div>
                          <div class="mt-1 text-gray-500">
                            {{ meter.supplier_name }}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td
                      class="whitespace-nowrap px-3 py-5 text-sm text-gray-500"
                    >
                      {{ meter.customer_name }}
                    </td>
                    <td
                      class="whitespace-nowrap px-3 py-5 text-sm text-gray-500"
                    >
                      <div class="text-gray-900">{{ meter.address }}</div>
                      <div class="mt-1 text-gray-500">
                        {{ meter.description }}
                      </div>
                    </td>
                    <td
                      class="whitespace-nowrap px-3 py-5 text-sm text-gray-500"
                    >
                      <span
                        class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset"
                        :class="{
                          'bg-green-50 text-green-700 ring-green-600/20':
                            meter.status === 'active',
                          'bg-red-50 text-red-700 ring-red-600/20':
                            meter.status === 'inactive',
                          'bg-blue-50 text-blue-700 ring-blue-600/20':
                            meter.status === 'closed',
                        }"
                        >{{ meter.status }}</span
                      >
                    </td>
                    <td
                      class="whitespace-nowrap px-3 py-5 text-sm text-gray-500"
                    >
                      {{ formatDate(meter.last_consumption_date) }}
                    </td>
                    <td
                      class="whitespace-nowrap px-3 py-5 text-sm text-gray-500"
                    >
                      {{ formatDate(meter.last_scanned_date) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div> -->

      <div class="mt-5 mb-0">
        <hr />
      </div>

      <div class="w-full grid grid-cols-2">
        <div class="justify-start m-4 text-sm text-gray-600">
          Viewing {{ 1 + (page - 1) * page_size }} - {{ page * page_size }} of
          {{ total_count }} results
        </div>
        <div class="flex justify-end mr-5">
          <button
            type="button"
            v-if="has_less"
            class="m-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            @click="getPrevious"
          >
            Previous
          </button>
          <button
            type="button"
            v-if="has_more"
            class="m-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            @click="getNext"
          >
            Next
          </button>
        </div>
      </div>
    </div>
    <aside
      class="mt-28 m-5 w-1/3 bg-white hidden xl:block border rounded-lg font-mono text-xs bg-gray-50"
      v-if="developer_tools"
    >
      <div v-for="response in responses" :key="response">
        <div class="bg-gray-200 text-sm p-2">Request</div>
        <div class="ml-2 mt-2 mb-2 whitespace-pre overflow-x-auto">
          {{ JSON.stringify(response.config, null, 2) }}
        </div>
        <div class="bg-gray-200 text-sm p-2">Response</div>
        <div class="ml-2 mt-2 mb-2 whitespace-pre overflow-x-auto">
          {{ JSON.stringify(response.data, null, 2) }}
        </div>
        <hr />
      </div>
    </aside>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import axios from 'axios';
import * as dateMath from 'date-arithmetic';
import ServerResponse from '../components/ServerResponse';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import {
  ChevronDownIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChartBarIcon,
} from '@heroicons/vue/solid';
import MoveDevice from '../components/MoveDevice.vue';
import { MailIcon, LightningBoltIcon } from '@heroicons/vue/solid';
import moment from 'moment';

export default {
  components: {
    ServerResponse,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    ChartBarIcon,
    MoveDevice,
    MailIcon,
    LightningBoltIcon,
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
  },

  data() {
    return {
      dateMath,
      serverResponse: '',
      responses: [],
      customers: [],
      api_key: '',
      page: 1,
      page_size: 20,
      customer_id: '',
      has_more: true,
      has_less: true,
      total_count: 0,
      developer_tools: false,
    };
  },
  methods: {
    getNext() {
      this.page += 1;
      this.submit();
    },
    getPrevious() {
      this.page -= 1;
      this.submit();
    },
    sort(attribute) {
      this.sort_by = attribute;
      if (this.sort_order == 'desc') this.sort_order = 'asc';
      else this.sort_order = 'desc';
      this.submit();
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YY');
      }
    },
    raiseErrorAlert(err) {
      let error_message = '';
      if (err.response) {
        error_message = err.response.data.error;
      } else {
        error_message = err.message;
      }

      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'error',
        message: error_message,
      });
    },
    submit() {
      this.makeHTTPRequest(
        `/customers?page=${this.page}&page_size=${this.page_size}`
      );
    },
    showDetails(customer_id) {
      this.$router.push('/customers/' + customer_id);
    },

    makeHTTPRequest(url) {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': this.api_key,
        },
      };
      axios.get(url, config).then((res) => {
        console.log(res.data.data);
        this.customers = res.data.data;
        this.total_count = res.data.total_count;
        this.serverResponse = JSON.stringify(res, null, 2);
        this.responses = [];
        let entry = {
          config: res.config,
          data: res.data,
        };
        this.responses.push(entry);
      });
    },

    loadPage() {
      const currentAccountSession = this.$store.state.clime_account.name;
      const currentProjectSession = this.$store.state.clime_project.name;

      //let's reset the params
      this.customer_id = '';
      this.page = 1;
      this.page_size = 20;

      console.log(
        'this.$store.state.clime_account.name: ' +
          this.$store.state.clime_account.name
      );

      axios
        .get(`/core_accounts/${this.$store.state.clime_account.id}`)
        .then((res) => {
          //Let's get the API key for this account before we do anything else
          this.api_key = res.data.data.api_key;
          this.makeHTTPRequest(
            `/customers?page=${this.page}&page_size=${this.page_size}`
          );
        });
    },
  },

  beforeCreate() {
    let _this = this;
    this.$store.watch(
      () => this.$store.state.clime_account.id,
      function () {
        _this.loadPage();
      }
    );
    this.$store.watch(
      () => this.$store.state.clime_project.name,
      function () {
        _this.loadPage();
      }
    );
  },

  created() {
    console.log('Customers created called!!');
    this.loadPage();
  },
};
</script>
