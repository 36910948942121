import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

const store = new createStore({
  plugins: [createPersistedState({ storage: window.localStorage })],
  state() {
    return {
      userAlert: {
        visible: false,
        alert_type: '',
        message: '',
      },
      coreThingsUser: {
        is_user: false,
        email: null,
      },
      clime_account: {
        name: null,
        role: null,
      },
      clime_project: {
        name: null,
        role: null,
      },
      user: {
        email: null,
      },
    };
  },
  mutations: {
    updateUserAlert(state, payload) {
      state.userAlert.visible = payload.visible;
      state.userAlert.alert_type = payload.alert_type;
      state.userAlert.message = payload.message;
    },
    updateUserAccount(state, payload) {
      state.clime_account.name = payload.account_name;
      state.clime_account.id = payload.account_id;
    },
    updateCoreThingsUser(state, payload) {
      state.coreThingsUser.is_user = payload.is_user;
    },
    updateUser(state, payload) {
      state.user.email = payload.email;
    },
  },
});

export default store;
