<template>
  <div class="flex w-full">
    <div class="w-full" :class="{ 'xl:w-2/3': developer_tools }">
      <div class="grid grid-cols-2 ml-5 mt-10 text-xl font-large text-gray-700">
        <div>List all Utility Accounts</div>
        <div class="flex justify-end hover:cursor-pointer">
          <ChevronDoubleRightIcon
            v-if="developer_tools"
            class="h-5 w-5 text-gray-400"
            aria-hidden="true"
            @click="developer_tools = false"
          />
          <ChevronDoubleLeftIcon
            v-else
            class="h-5 w-5 text-gray-400"
            aria-hidden="true"
            @click="developer_tools = true"
          />
        </div>
      </div>
      <div class="flex">
        <div class="ml-4 text-sm font-sm text-gray-500">
          <button class="bg-green-200 rounded-full text-xs font-xs px-2 mx-1">
            GET</button
          >https://api.coreclimate.io/v1/utility_accounts
        </div>
      </div>
      <div>
        <hr class="mt-2 mb-5 mx-5" />
      </div>

      <div>
        <div class="ml-5 mt-10 text-sm font-small text-gray-500"></div>
      </div>

      <form class="mb-5">
        <div class="ml-5 mr-5">
          <div>
            <h2 class="text-sm text-gray-900">QUERY PARAMS</h2>

            <div
              class="mt-5 space-y-2 border border-gray-200 pb-0 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:pb-0 bg-gray-100 rounded-lg p-2 mb-5"
            >
              <div
                class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2"
              >
                <div>
                  <div class="flex">
                    <span
                      class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                      >page</span
                    ><span
                      class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                      >integer</span
                    >
                  </div>

                  <div class="text-sm font-sm text-gray-600">
                    The page number to retrieve
                  </div>
                </div>

                <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <div
                    class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md"
                  >
                    <input
                      type="text"
                      class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                      v-model="page"
                    />
                  </div>
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2"
              >
                <div>
                  <div class="flex">
                    <span
                      class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                      >page_size</span
                    ><span
                      class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                      >integer</span
                    >
                  </div>

                  <div class="text-sm font-sm text-gray-600">
                    The maximum number of utility_accounts to retrieve per page
                  </div>
                </div>

                <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <div
                    class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md"
                  >
                    <input
                      type="text"
                      class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                      v-model="page_size"
                    />
                  </div>
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2"
              >
                <div>
                  <div class="flex">
                    <span
                      class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                      >utility_account_number</span
                    ><span
                      class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                      >integer</span
                    >
                  </div>

                  <div class="text-sm font-sm text-gray-600">
                    filter by utility_account_number
                  </div>
                </div>

                <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <div
                    class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md"
                  >
                    <input
                      type="text"
                      class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                      v-model="utility_account_number"
                    />
                  </div>
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2"
              >
                <div>
                  <div class="flex">
                    <span
                      class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                      >utility_account_id</span
                    ><span
                      class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                      >integer</span
                    >
                  </div>

                  <div class="text-sm font-sm text-gray-600">
                    Filter by utility_account_id
                  </div>
                </div>

                <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <div
                    class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md"
                  >
                    <input
                      type="text"
                      class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                      v-model="utility_account_id"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="flex border-0 justify-end">
              <button
                type="button"
                class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                @click="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>

      <div class="px-4 sm:px-6 lg:px-8">
        <div class="sm:flex sm:items-center">
          <div class="sm:flex-auto">
            <h1 class="text-base font-semibold leading-6 text-gray-900">
              Utility
            </h1>
            <p class="mt-2 text-sm text-gray-700">
              A list of all the utility_accounts using the filtering above
            </p>
          </div>
        </div>
        <div class="mt-8 flow-root">
          <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div
              class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
            >
              <table class="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      Utility Account
                    </th>
                    <th
                      scope="col"
                      class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Utility Supplier
                    </th>
                    <th
                      scope="col"
                      class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Credentials
                    </th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                  <tr
                    v-for="utility_account in utility_accounts"
                    :key="utility_account.account_number"
                  >
                    <td
                      class="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0"
                    >
                      <div class="flex items-center">
                        <div class="h-11 w-11">
                          <img
                            class="h-19 w-11"
                            :src="require('@/assets/sse.png')"
                            alt=""
                          />
                        </div>
                        <div
                          class="ml-4 hover:cursor-pointer"
                          @click="showDetails(utility_account._id)"
                        >
                          <div class="font-medium text-gray-900">
                            {{ utility_account.account_number }}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td
                      class="whitespace-nowrap px-3 py-5 text-sm text-gray-500"
                    >
                      {{ utility_account.utility_supplier_name }}
                    </td>
                    <td
                      class="whitespace-nowrap px-3 py-5 text-sm text-gray-500"
                    >
                      <div class="text-gray-900">
                        {{ utility_account.credentials[0].username }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-5 mb-0">
        <hr />
      </div>

      <div class="w-full grid grid-cols-2">
        <div class="justify-start m-4 text-sm text-gray-600">
          Viewing {{ 1 + (page - 1) * page_size }} - {{ page * page_size }} of
          {{ total_count }} results
        </div>
        <div class="flex justify-end mr-5">
          <button
            type="button"
            v-if="has_less"
            class="m-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            @click="getPrevious"
          >
            Previous
          </button>
          <button
            type="button"
            v-if="has_more"
            class="m-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            @click="getNext"
          >
            Next
          </button>
        </div>
      </div>
    </div>
    <aside
      class="mt-28 m-5 w-1/3 bg-white hidden xl:block border rounded-lg font-mono text-xs bg-gray-50"
      v-if="developer_tools"
    >
      <div v-for="response in responses" :key="response">
        <div class="bg-gray-200 text-sm p-2">Request</div>
        <div class="ml-2 mt-2 mb-2 whitespace-pre overflow-x-auto">
          {{ JSON.stringify(response.config, null, 2) }}
        </div>
        <div class="bg-gray-200 text-sm p-2">Response</div>
        <div class="ml-2 mt-2 mb-2 whitespace-pre overflow-x-auto">
          {{ JSON.stringify(response.data, null, 2) }}
        </div>
        <hr />
      </div>
    </aside>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import axios from 'axios';
import * as dateMath from 'date-arithmetic';
import ServerResponse from '../components/ServerResponse';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import {
  ChevronDownIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChartBarIcon,
} from '@heroicons/vue/solid';
import MoveDevice from '../components/MoveDevice.vue';
import { MailIcon, LightningBoltIcon } from '@heroicons/vue/solid';
import moment from 'moment';

export default {
  components: {
    ServerResponse,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    ChartBarIcon,
    MoveDevice,
    MailIcon,
    LightningBoltIcon,
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
  },

  data() {
    return {
      dateMath,
      serverResponse: '',
      responses: [],
      utility_accounts: [],
      api_key: '',
      page: 1,
      page_size: 20,
      utility_account_number: '',
      utility_account_id: '',
      has_more: true,
      has_less: true,
      total_count: 0,
      developer_tools: false,
    };
  },
  methods: {
    getNext() {
      this.page += 1;
      this.submit();
    },
    getPrevious() {
      this.page -= 1;
      this.submit();
    },
    sort(attribute) {
      this.sort_by = attribute;
      if (this.sort_order == 'desc') this.sort_order = 'asc';
      else this.sort_order = 'desc';
      this.submit();
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YY');
      }
    },
    raiseErrorAlert(err) {
      let error_message = '';
      if (err.response) {
        error_message = err.response.data.error;
      } else {
        error_message = err.message;
      }

      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'error',
        message: error_message,
      });
    },
    submit() {
      this.makeHTTPRequest(
        `/utility_accounts?page=${this.page}&page_size=${this.page_size}&utility_account_number=${this.account_number}&utility_account_id=${this.utility_account_id}&sort_by=${this.sort_by}&sort_order=${this.sort_order}`
      );
    },
    showDetails(utility_account_id) {
      this.$router.push('/utilitiy_accounts/' + utility_account_id);
    },

    makeHTTPRequest(url) {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': this.api_key,
        },
      };
      axios.get(url, config).then((res) => {
        console.log(res.data.data);
        this.utility_accounts = res.data.data;
        this.total_count = res.data.total_count;
        this.serverResponse = JSON.stringify(res, null, 2);
        this.responses = [];
        let entry = {
          config: res.config,
          data: res.data,
        };
        this.responses.push(entry);
      });
    },

    loadPage() {
      const currentAccountSession = this.$store.state.clime_account.name;
      const currentProjectSession = this.$store.state.clime_project.name;

      //let's reset the params
      this.utility_account_number = '';
      this.utility_account_id = '';
      this.page = 1;
      this.page_size = 20;

      console.log(
        'this.$store.state.clime_account.name: ' +
          this.$store.state.clime_account.name
      );

      axios
        .get(`/core_accounts/${this.$store.state.clime_account.id}`)
        .then((res) => {
          //Let's get the API key for this account before we do anything else
          this.api_key = res.data.data.api_key;
          this.makeHTTPRequest(
            `/utility_accounts?page=${this.page}&page_size=${this.page_size}`
          );
        });
    },
  },

  beforeCreate() {
    let _this = this;
    this.$store.watch(
      () => this.$store.state.clime_account.id,
      function () {
        _this.loadPage();
      }
    );
    this.$store.watch(
      () => this.$store.state.clime_project.name,
      function () {
        _this.loadPage();
      }
    );
  },

  created() {
    console.log('UtilityAccounts created called!!');
    this.loadPage();
  },
};
</script>
