import { createRouter, createWebHistory } from 'vue-router';

import Meters from './pages/Meters.vue';
import MeterDetail from './pages/MeterDetail.vue';
import Customers from './pages/Customers.vue';
import CustomerDetail from './pages/CustomerDetail.vue';
import UtilityAccounts from './pages/UtilityAccounts.vue';
import ProjectSettings from './pages/ProjectSettings.vue';
import AccountSettings from './pages/AccountSettings.vue';
import Account from './pages/account-settings/Account.vue';
import Preferences from './pages/project-settings/Preferences.vue';
import Subscriptions from './pages/account-settings/Subscriptions.vue';
import Billing from './pages/account-settings/Billing.vue';
import Logs from './pages/account-settings/Logs.vue';
import StripeCheckoutRedirect from './pages/project-settings/StripeCheckoutRedirect.vue';
import AccountUsers from './pages/account-settings/AccountUsers.vue';
import AccountUserDetail from './pages/account-settings/AccountUserDetail.vue';
import ProjectUsers from './pages/project-settings/ProjectUsers.vue';
import ProjectUserDetail from './pages/project-settings/ProjectUserDetail.vue';
import Projects from './pages/account-settings/Projects.vue';
import ProjectDetail from './pages/account-settings/ProjectDetail.vue';

import store from './store.js';

const routes = [
  {
    name: '',
    path: '/',
    component: Customers,
  },

  {
    name: 'meters',
    path: '/meters',
    component: Meters,
  },
  {
    name: 'meter-detail',
    path: '/meters/:id',
    component: MeterDetail,
  },
  {
    name: 'customers',
    path: '/customers',
    component: Customers,
  },
  {
    name: 'customer-detail',
    path: '/customers/:id',
    component: CustomerDetail,
  },
  {
    name: 'utility-accounts',
    path: '/utility-accounts',
    component: UtilityAccounts,
  },

  {
    name: 'project-settings',
    path: '/project-settings',
    component: ProjectSettings,
  },
  {
    name: 'account-settings',
    path: '/account-settings',
    component: AccountSettings,
  },
  {
    name: 'account',
    path: '/account-settings/account',
    component: Account,
  },
  {
    name: 'preferences',
    path: '/project-settings/preferences',
    component: Preferences,
  },
  {
    name: 'subscriptions',
    path: '/account-settings/subscriptions',
    component: Subscriptions,
  },
  {
    name: 'billing',
    path: '/account-settings/billing',
    component: Billing,
  },
  {
    name: 'logs',
    path: '/account-settings/logs',
    component: Logs,
  },
  {
    name: 'stripe-checkout-redirect',
    path: '/project-settings/stripe-checkout-redirect',
    component: StripeCheckoutRedirect,
  },
  {
    name: 'account-users',
    path: '/account-settings/account-users',
    component: AccountUsers,
  },
  {
    name: 'account-user-detail',
    path: '/account-settings/account-users/:id',
    component: AccountUserDetail,
  },
  {
    name: 'project-users',
    path: '/project-settings/project-users',
    component: ProjectUsers,
  },
  {
    name: 'project-user-detail',
    path: '/project-settings/project-users/:id',
    component: ProjectUserDetail,
  },
  {
    name: 'projects',
    path: '/account-settings/projects',
    component: Projects,
  },
  {
    name: 'project-detail',
    path: '/account-settings/projects/:id',
    component: ProjectDetail,
  },

  // { path: '/:notFound(.*)', component: NotFound },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    // console.log('scrollBehavior()');
    // console.log(to);
    // console.log(from);
    return { top: 0 };
  },
});

router.beforeEach(function (to, from, next) {
  // store.commit({
  //   type: 'updateUserAlert',
  //   visible: false,
  // });
  next();
});

export default router;
